// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

// import { DeriveBalancesAccount } from '@polkadot/api-derive/types';
import styled from '@xstyled/styled-components';
// import { add } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'src/context/ApiContext';
// import { chainProperties } from 'src/global/networkConstants';
// import formatBnBalance from 'src/util/formatBnBalance';
import getNetwork from 'src/util/getNetwork';
import Web3 from 'web3';

export const u64FromCurrency = (currency: string): number => {
	const buf = Buffer.from(currency.toLowerCase());
	const size = buf.length;

	return buf.reduce(
		(val, digit, i) => val + Math.pow(256, size - 1 - i) * digit,
		0
	);
};

export const convertToNumber = (val: string) => {
	const multiplier = val.split(' ')[1][0];
	console.log('multiplier', multiplier);
	if (multiplier == 'k'){
		return parseFloat(val) * 1000;
	}
	else if (multiplier == 'M'){
		return parseFloat(val) * 1000000;
	}
	else if (['b', 'B'].includes(multiplier)){
		return parseFloat(val) * 1000000000;
	}
	return 0;
};

interface Props {
	address: string
	className?: string
	isReferendum?: boolean
}

const network = getNetwork();

const Balance = ({ address, className, isReferendum = false }: Props) => {
	const [balance, setBalance] = useState<string>('0');
	const { api, apiReady } = useContext(ApiContext);

	useEffect(() => {

		if (!address) {
			return;
		}

		if (['moonbase', 'moonbeam', 'moonriver'].includes(network)) {
			const web3 = new Web3(Web3.givenProvider);

			web3.eth.getBalance(address)
				.then((result) => {
					setBalance(result);
				})
				.catch(e => console.error(e));
		} else {
			if (!api) {
				return;
			}

			if (!apiReady) {
				return;
			}

			if(['robonomics', 'hydradx', 'basilisk'].includes(network)){
				api.query.system.account(address)
					.then((result) => {
						if(isReferendum){
							setBalance(result.data?.free?.toString() || '0');
						}
						else if (result.data.free && result.data?.free?.toBigInt() >= result.data?.miscFrozen?.toBigInt()){
							setBalance((result.data?.free?.toBigInt() - result.data?.miscFrozen?.toBigInt()).toString() || '0');
						}
						else{
							setBalance('0');
						}
					})
					.catch(e => console.error(e));
			}
			else{
				if (['genshiro', 'equilibrium'].includes(network)){
					api.query.eqBalances.account(address, { '0': 1734700659 })
						.then((result: any) => {
						// console.log('set', convertToNumber(result.toHuman()?.Positive?.toString()).toString())
							console.log('set', result.toHuman()?.Positive?.toString());
							setBalance(result.toHuman()?.Positive?.toString() || '0');
						})
						.catch(e => console.error(e));

				}
				else{
					api.query.balances.account(address)
						.then((result) => {
							setBalance(result.free?.toString() || '0');
						})
						.catch(e => console.error(e));
				}
			}
		}

	}, [address, api, apiReady, isReferendum]);

	return (
		<div className={className}>
			<span>{balance}</span> available.
		</div>
	);
};

export default styled(Balance)`
	color: black_text;
	span {
		color: black_primary;
		font-weight: 500;
	}
`;
