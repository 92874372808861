// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import React from 'react';
import { ReactComponent as MetaMaskIcon } from 'src/assets/wallet/metamask-icon.svg';
import { ReactComponent as NovaWalletIcon } from 'src/assets/wallet/nova-wallet-star.svg';
import { ReactComponent as PolkadotJSIcon } from 'src/assets/wallet/polkadotjs-icon.svg';
import { ReactComponent as SubWalletIcon } from 'src/assets/wallet/subwallet-icon.svg';
import { ReactComponent as TalismanIcon } from 'src/assets/wallet/talisman-icon.svg';
import { Wallet } from 'src/types';
import Modal from 'src/ui-components/WalletModal';

import WalletButton from '../WalletButton';

interface Props {
	showWalletModal: boolean
	onWalletSelect: (wallet: Wallet) => void
	setShowWalletModal: React.Dispatch<React.SetStateAction<boolean>>
}

const WalletsModal = ({ showWalletModal, onWalletSelect, setShowWalletModal } : Props) => {

	return (
		<Modal size="mini" open={showWalletModal} onClose={() => setShowWalletModal(false)} title="Choose wallet to connect">
			<WalletButton onClick={() => onWalletSelect(Wallet.POLKADOT)} name="Polkadot.js" icon={<PolkadotJSIcon />} />
			<WalletButton onClick={() => onWalletSelect(Wallet.TALISMAN)} name="Talisman" icon={<TalismanIcon />} />
			<WalletButton onClick={() => onWalletSelect(Wallet.SUBWALLET)} name="SubWallet" icon={<SubWalletIcon />} />
			<WalletButton onClick={() => onWalletSelect(Wallet.METAMASK)} name="MetaMask" icon={<MetaMaskIcon/>} />
			{
				(window as any).walletExtension?.isNovaWallet &&
					<WalletButton onClick={() => onWalletSelect(Wallet.NOVAWALLET)} name="Nova Wallet" icon={<NovaWalletIcon />} />
			}
		</Modal>
	);
};

export default WalletsModal;
