// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import styled from '@xstyled/styled-components';
import BN from 'bn.js';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownProps, Table } from 'semantic-ui-react';
import { NotificationContext } from 'src/context/NotificationContext';
import { LoadingStatusType, NotificationStatus } from 'src/types';
import Button from 'src/ui-components/Button';
import { Form } from 'src/ui-components/Form';
import Loader from 'src/ui-components/Loader';
import Web3 from 'web3';

import { ApiContext } from '../../context/ApiContext';
import { chainProperties } from '../../global/networkConstants';
import AccountSelectionForm from '../../ui-components/AccountSelectionForm';
import formatBnBalance from '../../util/formatBnBalance';
import getNetwork from '../../util/getNetwork';

const abi = require('../../moonbeamAbi.json');

const currentNetwork = getNetwork();

interface Props {
	className?: string
}

interface Vote {
	refIndex: BN;
	vote: boolean;
	amount: BN;
}

const contractAddress = process.env.REACT_APP_DEMOCRACY_PRECOMPILE;

const DemocracyUnlock = ({ className }: Props) => {
	const { queueNotification } = useContext(NotificationContext);
	const [address, setAddress] = useState<string>('');
	const [votes, setVotes] = useState<Vote[]>([]);
	const [lockedBalance, setLockedBalance] = useState<BN>(new BN(0));
	const [loadingStatus, setLoadingStatus] = useState<LoadingStatusType>({ isLoading: false, message: '' });
	const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);
	const [isAccountLoading, setIsAccountLoading] = useState(true);
	const { api, apiReady } = useContext(ApiContext);

	useEffect(() => {
		if (!accounts.length) {
			getAccounts();
		}
	}, [accounts.length]);

	useEffect(() => {
		if (address) {
			getLockedBalance();
		}
	}, [api, apiReady, address]); // eslint-disable-line react-hooks/exhaustive-deps

	const getLockedBalance = async () => {
		if (!api) {
			return;
		}

		if (!apiReady) {
			return;
		}

		const votingInfo = await api.query.democracy.votingOf(address);

		setVotes(votingInfo.asDirect.votes.map((vote) => {
			const refIndex = vote[0];

			return {
				amount: vote[1].asStandard.balance,
				refIndex,
				vote: vote[1].asStandard.vote.isAye
			};
		}));

		const balances = await api.query.balances.locks(address);

		let lockedBalance = new BN(0);
		balances.forEach((balance) => {
			lockedBalance = lockedBalance.add(balance.amount);
		});

		setLockedBalance(lockedBalance);
	};

	const getAccounts = async () => {
		const ethereum = (window as any).ethereum;

		if (!ethereum) {
			return;
		}

		const addresses = await ethereum.request({ method: 'eth_requestAccounts' });

		if (addresses.length === 0) {
			setIsAccountLoading(false);
			return;
		}

		setAccounts(addresses.map((address: string): InjectedAccountWithMeta => {
			const account = {
				address,
				meta: {
					genesisHash: null,
					name: 'metamask',
					source: 'metamask'
				}
			};

			return account;
		}));

		if (addresses.length > 0) {
			setAddress(addresses[0]);
		}

		setIsAccountLoading(false);
	};

	const onAccountChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		const addressValue = data.value as string;
		setAddress(addressValue);
	};

	const handleRemove = async (refIndex: BN) => {
		if (!api) {
			return;
		}

		if (!apiReady) {
			return;
		}

		const web3 = new Web3((window as any).ethereum);

		const chainId = await web3.eth.net.getId();

		if (chainId !== chainProperties[currentNetwork].chainId) {
			queueNotification({
				header: 'Wrong Network!',
				message: `Please change to ${currentNetwork} network`,
				status: NotificationStatus.ERROR
			});
			return;
		}

		const contract = new web3.eth.Contract(abi, contractAddress);

		console.log(address);
		console.log(contractAddress);

		// estimate gas.
		// https://docs.moonbeam.network/builders/interact/eth-libraries/deploy-contract/#interacting-with-the-contract-send-methods

		contract.methods
			.remove_vote(refIndex.toString())
			.send({
				from: address,
				to: contractAddress
			})
			.then((result: any) => {
				console.log(result);
				queueNotification({
					header: 'Success!',
					message: 'Remove Vote successful.',
					status: NotificationStatus.SUCCESS
				});
				getLockedBalance();
			})
			.catch((error: any) => {
				console.error('ERROR:', error);
				queueNotification({
					header: 'Failed!',
					message: error.message,
					status: NotificationStatus.ERROR
				});
				getLockedBalance();
			});
	};

	const handleUnlock = async () => {
		const web3 = new Web3((window as any).ethereum);

		const chainId = await web3.eth.net.getId();

		if (chainId !== chainProperties[currentNetwork].chainId) {
			queueNotification({
				header: 'Wrong Network!',
				message: `Please change to ${currentNetwork} network`,
				status: NotificationStatus.ERROR
			});
			return;
		}

		setLoadingStatus({ isLoading: true, message: 'Waiting for confirmation' });

		const contract = new web3.eth.Contract(abi, contractAddress);

		console.log(address);
		console.log(contractAddress);

		// estimate gas.
		// https://docs.moonbeam.network/builders/interact/eth-libraries/deploy-contract/#interacting-with-the-contract-send-methods

		contract.methods
			.unlock(address)
			.send({
				from: address,
				to: contractAddress
			})
			.then((result: any) => {
				console.log(result);
				setLoadingStatus({ isLoading: false, message: '' });
				queueNotification({
					header: 'Success!',
					message: 'Unlock successful.',
					status: NotificationStatus.SUCCESS
				});
				getLockedBalance();
			})
			.catch((error: any) => {
				setLoadingStatus({ isLoading: false, message: '' });
				console.error('ERROR:', error);
				queueNotification({
					header: 'Failed!',
					message: error.message,
					status: NotificationStatus.ERROR
				});
				getLockedBalance();
			});
	};

	const GetAccountsButton = () =>
		<Form.Group>
			<Form.Field className='button-container'>
				<Button
					primary
					onClick={getAccounts}
					size={'large'}
				>
					Vote
				</Button>
			</Form.Field>
		</Form.Group>;

	const noAccount = accounts.length === 0;

	return (
		<div className={className}>
			{noAccount
				? <GetAccountsButton />
				: null
			}
			{isAccountLoading || loadingStatus.isLoading
				? <Loader text={loadingStatus.message} />
				: null
			}
			<Form standalone={false} id='democracyUnlock'>
				<Form.Group>
					<Form.Field width={12}>
						<label>Unlock democracy locks</label>
						<AccountSelectionForm
							title='Choose account'
							accounts={accounts}
							address={address}
							onAccountChange={onAccountChange}
						/>
					</Form.Field>
				</Form.Group>
				<Form.Group>
					<Form.Field width={8}>
						{lockedBalance.isZero()
							? <div>You currently have no democracy locks.</div>
							: <div>Your locked balance: <span className='bold'>{formatBnBalance(lockedBalance, { numberAfterComma: 2, withUnit: true })}.</span></div>
						}
						{votes.length ? <Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Proposal</Table.HeaderCell>
									<Table.HeaderCell>Locked</Table.HeaderCell>
									<Table.HeaderCell></Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{votes.map(vote => {
									return (
										<Table.Row key={vote.refIndex.toString()}>
											<Table.Cell width={10}>
												<div className='item'>
													<Link to={`/proposal/${vote.refIndex.toString()}`}>
														Proposal #{vote.refIndex.toString()}
													</Link>
												</div>
											</Table.Cell>
											<Table.Cell width={10}>
												<div className='item'>
													{formatBnBalance(vote.amount, { numberAfterComma: 2, withUnit: true })}
												</div>
											</Table.Cell>
											<Table.Cell width={8}>
												<div className='button-container'>
													<Button
														primary
														onClick={() => handleRemove(vote.refIndex)}
													>
														Remove
													</Button>

												</div>
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table> : <>
							<Button
								primary
								onClick={handleUnlock}
								type="submit"
							>
								Unlock
							</Button>
						</>}
					</Form.Field>
				</Form.Group>
			</Form>
		</div>
	);
};

export default styled(DemocracyUnlock)`
	.LoaderWrapper {
		height: 40rem;
		position: absolute;
		width: 100%;
	}
	.bold {
		font-weight: bold;
	}
`;
