// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'src/context/ApiContext';
import NothingFoundCard from 'src/ui-components/NothingFoundCard';

import CouncilListing from '../../../components/Listings/CouncilListing';
import FilteredError from '../../../ui-components/FilteredError';

const MotionsContainer = () => {
	const { api, apiReady } = useContext(ApiContext);
	const [error, setErr] = useState<Error | null>(null);
	const [members, setMembers] = useState<string[]>([]);
	const [runnersUp, setRunnersup] = useState<string[]>([]);
	const [prime, setPrime] = useState<string>('');

	useEffect(() => {
		if (!api) {
			return;
		}

		if (!apiReady) {
			return;
		}

		try{
			if(api.query.council){
				api.query.council.prime().then(primeId => {
					setPrime(primeId.unwrapOr('').toString());
				}).catch(error => setErr(error));

				api.query.council.members().then((members) => {
					setMembers(members.map(member => member.toString()));
				}).catch(error => setErr(error));

				api.derive.elections.info().then((electionInfo) => {
					setRunnersup(electionInfo.runnersUp.map(runner => runner.toString().split(',')[0]));
				}).catch(error => setErr(error));
			}
			else if(api.query.generalCouncil) {
				api.query.generalCouncil.prime().then(primeId => {
					setPrime(primeId.toString());
				}).catch(error => setErr(error));

				api.query.generalCouncil.members().then((members) => {
					// eslint-disable-next-line no-useless-escape
					setMembers(members.toString().split(',').map(member => member.replace(/[\[\]\s]+/g,'').toString()));
				}).catch(error => setErr(error));
			}
		}
		catch(error){
			setErr(error);
		}

	}, [api, apiReady]);

	if (error) return <FilteredError text={error.message}/>;

	if (members.length || runnersUp.length) {
		return <CouncilListing members={members} prime={prime} runnersUp={runnersUp} />;
	}

	return <NothingFoundCard text='No data to display'/>;
};

export default MotionsContainer;
