// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import styled from '@xstyled/styled-components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DropdownProps, Input, Select } from 'semantic-ui-react';
import { NotificationContext } from 'src/context/NotificationContext';
import { LoadingStatusType, NotificationStatus } from 'src/types';
import Button from 'src/ui-components/Button';
import Card from 'src/ui-components/Card';
import { Form } from 'src/ui-components/Form';
import HelperTooltip from 'src/ui-components/HelperTooltip';
import Loader from 'src/ui-components/Loader';
import Web3 from 'web3';

import { chainProperties } from '../../../../global/networkConstants';
import AccountSelectionForm from '../../../../ui-components/AccountSelectionForm';
import AyeNayButtons from '../../../../ui-components/AyeNayButtons';
import getNetwork from '../../../../util/getNetwork';

const abi = require('../../../../moonbeamAbi.json');

const currentNetwork = getNetwork();

interface Props {
	className?: string
	referendumId?: number | null | undefined
}

const contractAddress = process.env.REACT_APP_DEMOCRACY_PRECOMPILE;

const VoteRefrendum = ({ className, referendumId }: Props) => {
	const { queueNotification } = useContext(NotificationContext);
	const [address, setAddress] = useState<string>('');
	const [loadingStatus, setLoadingStatus] = useState<LoadingStatusType>({ isLoading: false, message: '' });
	const CONVICTIONS: [number, number][] = [1, 2, 4, 8, 16, 32].map((lock, index) => [index + 1, lock]);
	const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);
	const [isAccountLoading, setIsAccountLoading] = useState(true);
	const [lockedBalance, setLockedBalance] = useState<number>(0);
	const convictionOpts = useMemo(() => [
		{ text: '0.1x voting balance, no lockup period', value: 0 },
		...CONVICTIONS.map(([value, lock]): { text: string; value: number } => ({
			text: `${value}x balance, locked for ${lock}x enactment (${lock} days)`,
			value
		}))
	],[CONVICTIONS]);
	const [conviction, setConviction] = useState<number>(convictionOpts[0].value);

	useEffect(() => {
		if (!accounts.length) {
			getAccounts();
		}
	}, [accounts.length]);

	const getAccounts = async () => {
		const ethereum = (window as any).ethereum;

		if (!ethereum) {
			return;
		}

		const addresses = await ethereum.request({ method: 'eth_requestAccounts' });

		if (addresses.length === 0) {
			setIsAccountLoading(false);
			return;
		}

		setAccounts(addresses.map((address: string): InjectedAccountWithMeta => {
			const account = {
				address,
				meta: {
					genesisHash: null,
					name: 'metamask',
					source: 'metamask'
				}
			};

			return account;
		}));

		if (addresses.length > 0) {
			setAddress(addresses[0]);
		}

		setIsAccountLoading(false);
	};

	const onConvictionChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		setConviction(Number(data.value));
	};

	const onAccountChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		const addressValue = data.value as string;
		setAddress(addressValue);
	};

	const onBalanceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const balance = Number(event.currentTarget.value);
		if (!isNaN(balance)) {
			setLockedBalance(balance);
		}
	};

	const voteRefrendum = async (aye: boolean) => {
		if (!referendumId && referendumId !== 0) {
			console.error('referendumId not set');
			return;
		}

		if (!lockedBalance) {
			console.error('lockedBalance not set');
			return;
		}

		const web3 = new Web3((window as any).ethereum);

		const chainId = await web3.eth.net.getId();

		if (chainId !== chainProperties[currentNetwork].chainId) {
			queueNotification({
				header: 'Wrong Network!',
				message: `Please change to ${currentNetwork} network`,
				status: NotificationStatus.ERROR
			});
			return;
		}

		setLoadingStatus({ isLoading: true, message: 'Waiting for confirmation' });

		const voteContract = new web3.eth.Contract(abi, contractAddress);

		console.log(
			referendumId,
			aye,
			web3.utils.toWei(`${lockedBalance}`, 'ether'),
			conviction
		);

		console.log(address);
		console.log(contractAddress);

		// estimate gas.
		//https://docs.moonbeam.network/builders/interact/eth-libraries/deploy-contract/#interacting-with-the-contract-send-methods

		voteContract.methods
			.standard_vote(
				referendumId,
				aye,
				web3.utils.toWei(`${lockedBalance}`, 'ether'),
				conviction
			)
			.send({
				from: address,
				to: contractAddress
			})
			.then((result: any) => {
				console.log(result);
				setLoadingStatus({ isLoading: false, message: '' });
				queueNotification({
					header: 'Success!',
					message: `Vote on referendum #${referendumId} successful.`,
					status: NotificationStatus.SUCCESS
				});
			})
			.catch((error: any) => {
				setLoadingStatus({ isLoading: false, message: '' });
				console.error('ERROR:', error);
				queueNotification({
					header: 'Failed!',
					message: error.message,
					status: NotificationStatus.ERROR
				});
			});
	};

	const GetAccountsButton = () =>
		<Form.Group>
			<Form.Field className='button-container'>
				<Button
					primary
					onClick={getAccounts}
					size={'large'}
				>
					Vote
				</Button>
			</Form.Field>
		</Form.Group>;

	const noAccount = accounts.length === 0;

	const VoteLock = () =>
		<Form.Field>
			<label>Vote lock
				<HelperTooltip
					content='You can multiply your votes by locking your tokens for longer periods of time.'
				/>
			</label>
			<Select
				onChange={onConvictionChange}
				options={convictionOpts}
				pointing={'top'}
				value={conviction}
			/>
		</Form.Field>;

	return (
		<div className={className}>
			{ noAccount
				? <GetAccountsButton />
				: isAccountLoading || loadingStatus.isLoading
					? <Card className={'LoaderWrapper'}>
						<Loader text={loadingStatus.message}/>
					</Card>
					: <Card>
						<AccountSelectionForm
							title='Vote with account'
							accounts={accounts}
							address={address}
							withBalance
							onAccountChange={onAccountChange}
						/>
						<Form.Field className={className} width={16}>
							<label>
								Lock balance
								<HelperTooltip content={'Amount of you are willing to lock for this vote.'}/>
							</label>
							<Input
								className={'balanceInput'}
								invalid={isNaN(lockedBalance).toString()}
								onChange={onBalanceChange}
								placeholder='123'
								type='string'
							/>
						</Form.Field>
						<VoteLock/>
						<AyeNayButtons
							onClickAye={() => voteRefrendum(true)}
							onClickNay={() => voteRefrendum(false)}
						/>
					</Card>
			}
		</div>
	);
};

export default styled(VoteRefrendum)`
	.LoaderWrapper {
		height: 40rem;
		position: absolute;
		width: 100%;
	}
`;
