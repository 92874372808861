// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import styled from '@xstyled/styled-components';
import React, { useContext, useEffect, useState } from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { ApiContext } from 'src/context/ApiContext';
import Web3 from 'web3';

import { NotificationContext } from '../../../../context/NotificationContext';
import { LoadingStatusType,NotificationStatus } from '../../../../types';
import AccountSelectionForm from '../../../../ui-components/AccountSelectionForm';
import Button from '../../../../ui-components/Button';
import Card from '../../../../ui-components/Card';
import { Form } from '../../../../ui-components/Form';
import Loader from '../../../../ui-components/Loader';

const abi = require('../../../../moonbeamAbi.json');

export interface ChainProps {
	seconds: number
}
export interface SecondProposalProps {
	className?: string
	proposalId?: number | null | undefined
}

type Props = SecondProposalProps & ChainProps

const contractAddress = process.env.REACT_APP_DEMOCRACY_PRECOMPILE;

const SecondProposalEth = ({ className, proposalId, seconds }: Props) => {
	const [loadingStatus, setLoadingStatus] = useState<LoadingStatusType>({ isLoading: false, message:'' });
	const { queueNotification } = useContext(NotificationContext);
	const { api, apiReady } = useContext(ApiContext);
	const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);
	const [address, setAddress] = useState<string>('');
	const web3 = new Web3((window as any).ethereum);

	useEffect(() => {
		if (!accounts.length) {
			getAccounts();
		}
	}, [accounts.length]);

	const getAccounts = async () => {
		const ethereum = (window as any).ethereum;

		if (!ethereum) {
			return;
		}

		const addresses = await ethereum.request({ method: 'eth_requestAccounts' });

		if (addresses.length === 0) {
			return;
		}

		setAccounts(addresses.map((address: string): InjectedAccountWithMeta => {
			const account = {
				address,
				meta: {
					genesisHash: null,
					name: 'metamask',
					source: 'metamask'
				}
			};

			return account;
		}));

		if (addresses.length > 0) {
			setAddress(addresses[0]);
		}

	};

	const secondProposal = async () => {
		if (!proposalId && proposalId !== 0) {
			console.error('proposalId not set');
			return;
		}

		if (!api) {
			return;
		}

		if (!apiReady) {
			return;
		}

		setLoadingStatus({ isLoading: true, message: 'Waiting for confirmation' });

		const voteContract = new web3.eth.Contract(abi, contractAddress);

		console.log(
			proposalId, seconds
		);

		console.log(address);
		console.log(contractAddress);

		voteContract.methods
			.second(
				proposalId,
				seconds
			)
			.send({
				from: address,
				to: contractAddress
			})
			.then((result: any) => {
				console.log(result);
				setLoadingStatus({ isLoading: false, message: '' });
				queueNotification({
					header: 'Success!',
					message: `Vote on proposal #${proposalId} successful.`,
					status: NotificationStatus.SUCCESS
				});
			})
			.catch((error: any) => {
				setLoadingStatus({ isLoading: false, message: '' });
				console.error('ERROR:', error);
				queueNotification({
					header: 'Failed!',
					message: error.message,
					status: NotificationStatus.ERROR
				});
			});
	};

	const onAccountChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		const addressValue = data.value as string;
		setAddress(addressValue);
	};

	const GetAccountsButton = () =>
		<Form.Group>
			<Form.Field className='button-container'>
				<Button
					primary
					onClick={getAccounts}
					size={'large'}
				>
					Second
				</Button>
			</Form.Field>
		</Form.Group>;

	const noAccount = accounts.length === 0;

	return (
		<div className={className}>
			{ noAccount
				? <GetAccountsButton />
				:loadingStatus.isLoading
					? <Card className={'LoaderWrapper'}>
						<Loader text={loadingStatus.message}/>
					</Card>
					: <Card>
						<AccountSelectionForm
							title='Second with account'
							accounts={accounts}
							address={address}
							withBalance
							onAccountChange={onAccountChange}
						/>
						<Button
							primary
							disabled={!apiReady}
							onClick={secondProposal}
						>
							Second
						</Button>
					</Card>
			}
		</div>
	);
};

export default styled(SecondProposalEth)`
	.LoaderWrapper {
		height: 15rem;
		position: absolute;
		width: 100%;
	}
`;
