// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import styled from '@xstyled/styled-components';
import React, { useEffect, useState } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { REACT_APP_SUBSCAN_API_KEY } from 'src/global/apiKeys';
import HelperTooltip from 'src/ui-components/HelperTooltip';
import getNetwork from 'src/util/getNetwork';

import { CouncilVote, Vote } from '../../../../types';
import Address from '../../../../ui-components/Address';
import Card from '../../../../ui-components/Card';

const NETWORK = getNetwork();
interface Props {
	className?: string
	motionId: number
}

const MotionVoteInfo = ({ className, motionId }: Props) => {
	const [councilVotes, setCouncilVotes] = useState<CouncilVote[]>([]);

	const fetchCouncilVotes = async () => {
		const councilVotes: CouncilVote[] = [];

		try{
			const rawResponse = await fetch(`https://${NETWORK == 'heiko' ? 'parallel-heiko' : NETWORK === 'kilt' ? 'spiritnet' : NETWORK}.webapi.subscan.io/api/scan/council/proposal`, {
				body: JSON.stringify({ proposal_id: Number(motionId) }),
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'X-API-Key': REACT_APP_SUBSCAN_API_KEY
				},
				method: 'POST'
			});
			const data = await rawResponse.json();
			if(data['message'] == 'Success'){
				const votes = data.data?.info?.votes;
				if (votes){
					votes.forEach((vote: { account: { address: any; }; passed: any; }) => {
						councilVotes.push({
							address: vote.account.address,
							vote: vote.passed ? Vote.AYE : Vote.NAY
						});
					});
					setCouncilVotes(councilVotes);
				}
			}
		}
		catch(error){
			console.log(error);
			setCouncilVotes([]);
		}
	};

	useEffect(() => {
		fetchCouncilVotes();

	}, [motionId]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!councilVotes.length) {
		return null;
	}

	return (
		<Card className={className}>
			<h3>Council Votes <HelperTooltip content='This represents the onchain votes of council members'/></h3>
			<Grid className='council-votes'>
				{councilVotes.map(councilVote =>
					<Grid.Row key={councilVote.address}>
						<Grid.Column width={12}>
							<div className='item'>
								<Address address={councilVote.address} />
							</div>
						</Grid.Column>
						<Grid.Column width={4}>
							{councilVote.vote === Vote.AYE ? <>
								<div className='thumbs up'>
									<Icon name='thumbs up' />
								</div> Aye
							</> : <>
								<div className='thumbs down'>
									<Icon name='thumbs down' />
								</div> Nay
							</>}
						</Grid.Column>
					</Grid.Row>
				)}
			</Grid>
		</Card>
	);
};

export default styled(MotionVoteInfo)`
	.council-votes {
		margin-top: 2em;
	}
	.thumbs {
		display: inline-block;
		text-align: center;
		vertical-align: middle;
		color: white;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		font-size: 1rem;
	}

	.thumbs.up {
		background-color: green_primary;
	}

	.thumbs.down {
		background-color: red_primary;
	}
`;
