// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import queryString from 'query-string';

import { network as networkConstants } from '../global/networkConstants';
import { Network } from '../types';
/**
 * Return the current network
 *
 */

export default function (): Network {
	let network: string;

	const url = global.window.location.href;

	const qs = queryString.parse(global.window.location.search);

	if (qs.network) {
		network = qs.network.toString();
	} else {
		try {
			network = url.split('//')[1].split('.')[0];
		} catch (error) {
			network = 'kusama';
			console.error(error);
		}
	}

	const possibleNetworks = Object.values(networkConstants);

	if (!possibleNetworks.includes(network)) {
		network = 'kusama';
	}

	return network;
}
