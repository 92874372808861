// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import styled from '@xstyled/styled-components';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { ApiContext } from 'src/context/ApiContext';
import { useGetBlockchainEndpointsQuery } from 'src/generated/graphql';
import getNetwork from 'src/util/getNetwork';

interface Props {
	className?: string
	setSidebarHiddenFunc?: () => void
}

const NETWORK = getNetwork();

const RPCDropdown = ({ className, setSidebarHiddenFunc }: Props) => {
	const { wsProvider, setWsProvider } = useContext(ApiContext);
	const [endpoint, setEndpoint] = useState<string>(wsProvider);
	const [RPCOptions, setRPCOptions] = useState<DropdownItemProps[]>([]);

	const { data, error } = useGetBlockchainEndpointsQuery({
		variables: {
			network: NETWORK
		}
	});

	useEffect(() => {
		let cancel = false;
		if(cancel) return;

		const RPCOptionsLocal: DropdownItemProps[] = [
			<>
				<Dropdown.Header className='dropdown-header' content='RPC Endpoints' />
				<Dropdown.Divider />
			</>
		];

		data?.blockchain_endpoints.forEach((endpointData) => {
			const optionObj = {
				children: <span>{endpointData.display_label}</span>,
				value: endpointData.endpoint
			};

			RPCOptionsLocal.push(optionObj);
		});

		setRPCOptions(RPCOptionsLocal);

		return () => {
			cancel = true;
		};

	}, [data]);

	const dropdownLabel = () => {
		let label = '';

		data?.blockchain_endpoints.some((endpointData) => {
			if(endpointData.endpoint == endpoint){
				label = `${endpointData.display_label?.substring(4, endpointData.display_label.length)}`;
				return true;
			}
		});

		return <span className='label'>{label}</span>;
	};

	const handleEndpointChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		if(endpoint == `${data.value}`) return;

		setEndpoint(`${data.value}`);
		setWsProvider(`${data.value}`);
	};

	return (
		!error && data && data.blockchain_endpoints.length > 0 ? <Dropdown
			onClick={setSidebarHiddenFunc}
			className={className}
			pointing='top'
			onChange={handleEndpointChange}
			trigger={dropdownLabel()}
			options={RPCOptions}
			value={endpoint}
			scrolling
		/> :
			<></>
	);
};

export default styled(RPCDropdown)`
	margin-right: 12px;

	.label {
		color: #fff;
	}

	.dropdown-header {
		color: #999 !important;
	}
`;
